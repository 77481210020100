<template>
  <div class="movies-container">
    <Teleport to="#overlay">
      <transition name="appear">
        <div
          v-if="isPreloadTextVisible || isOverlayVisible || isShowreelVisible"
          ref="landingOverlay"
          class="landing-overlay flex flex-col items-center justify-center"
          @click="removeOverlay"
        >
          <div
            ref="preloadContainer"
            class="preload-text-container"
            :class="{ visible: isPreloadTextVisible, none: isPreloadtextNone }"
          >
            <h2 class="preload-text-quote" v-html="payload.preloadtext" />
            <h3 class="preload-text-author uppercase" v-html="payload.autorenname" />
          </div>
          <h1 class="preload-text-logo">
            schau.
          </h1>
          <ScrollIndicator :should-scroll="false" />
        </div>
      </transition>
    </Teleport>

    <Go
      v-for="movie in movies"
      :key="movie.id"
      :to="movie.data.link"
      class="single-movie-container"
    >
      <div class="single-movie-title-box flex flex-col justfify-center items-center">
        <h2 class="single-movie-title" v-html="movie.data.filmtitel" />
        <h3 class="single-movie-subtitle">
          {{ movie.data.regie }}
        </h3>
      </div>
      <video
        :src="movie.data.movieloop ? movie.data.movieloop[0].link : ''"
        class="video"
        playsinline
        autoplay
        muted
        disablePictureInPicture
        controlsList="nodownload noremoteplayback"
        loop
      />
    </Go>
  </div>
</template>

<script>
import ScrollIndicator from '../parts/ScrollIndicator.vue';

export default {
  components: {
    ScrollIndicator,
  },
  props: {
    payload: Object,
  },
  data() {
    return {
      movies: this.payload.videos,
      overlayTimeout: null,
      quoteFadeOut: null,
      displayChange: null,
      showreelFadeIn: null,
      isOverlayVisible: true,
      isOverlayNone: false,
      isPreloadTextVisible: true,
      isPreloadtextNone: false,
      isShowreelVisible: false,
      isShowreelNone: true,
    };
  },
  mounted() {
    const waitingTime = 5000;
    window.addEventListener('scroll', this.removeOverlay);
    this.quoteFadeOut = setTimeout(() => {
      this.isPreloadTextVisible = false;
    }, waitingTime);
    this.displayChange = setTimeout(() => {
      this.isPreloadtextNone = true;
      this.isShowreelNone = false;
    }, waitingTime + 600);
    const showreelFadeIn = setTimeout(() => {
    //  this.isShowreelVisible = true;
      this.removeOverlay();
    }, waitingTime + 700);
  },
  unmounted() {
    window.removeEventListener('scroll', this.removeOverlay);
    if (this.quoteFadeOut) { clearTimeout(this.quoteFadeOut); }
    if (this.displayChange) { clearTimeout(this.displayChange); }
    if (this.showreelFadeIn) { clearTimeout(this.showreelFadeIn); }
  },
  methods: {
    removeOverlay() {
      clearTimeout(this.overlayTimeout);
      this.isOverlayVisible = false;
      this.isShowreelVisible = false;
      this.isPreloadTextVisible = false;
      this.overlayTimeout = setTimeout(() => { this.isOverlayNone = true; }, 250);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

.movies-container {
  position: relative;
  width: 100%;
  height: auto;
}

.showreel-container {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.75s ease-in;
  &.visible {
    display: block;
    opacity: 1;
  }
  &.display {
    display: block;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.landing-overlay {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: var(--color-black);
  z-index: 101;
  transition: opacity 0.25s ease-out;

  .preload-text {
    &-container {
      transform: translateY(-5%);
      text-align: center;
      max-width: 75%;
      opacity: 0;
      transition: opacity 0.75s ease-out;
      @screen sm {
        max-width: 50%;
      }
      &.visible {
        opacity: 1;
      }
      &.none {
        display: none;
      }
    }
    &-quote :deep(p) {
      font-family: var(--font-primary);
      color: var(--color-red);
      font-size: 1.75rem;
      line-height: 1.1;
      margin-bottom: 2rem;
      @screen sm {
        font-size: 1.75rem;
      }
      @screen md {
        font-size: 2rem;
      }
      @screen lg {
        font-size: 3rem;
      }
    }
    &-author :deep(p) {
      color: var(--color-red);
      letter-spacing: 1px;
      font-size: 0.8rem;
      @screen sm {
        font-size: 1rem;
      }
      @screen md {
        font-size: 1.1rem;
      }
    }
    &-logo {
      position: absolute;
      bottom: 10%;
      margin-top: 4rem;
      font-size: 2.5rem;
      font-family: var(--font-primary);
      color: var(--color-white);
      @screen sm {
        margin-top: 6rem;
        font-size: 3rem;
      }
      @screen md {
        margin-top: 12rem;
        font-size: 3.5rem;
      }
      @screen lg {
        margin-top: 12rem;
        font-size: 5rem;
      }
    }
  }
}

.single-movie {
  &-container {
    display: block;
    position: relative;
    width: 100%;
    height: 100vh;
    .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &-title-box {
    margin: 0;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    max-width: 50%;
    min-width: 300px;
  }
  &-title {
    @apply font-sans;
    margin: 0;
    line-height: 1.25;
    :deep(p) {
      color: var(--color-yellow);
      font-size: 2.75rem;
      text-align: center;
      margin: 0;
      @media (max-width: theme("screens.lg")) {
        font-size: 2.25rem;
      }
      @media (max-width: theme("screens.md")) {
        font-size: 2rem;
      }
    }
  }
  &-subtitle {
    color: var(--color-white);
    text-align: center;
    text-transform: uppercase;
    font-size: 1.2rem;
    @media (max-width: theme("screens.lg")) {
      font-size: 1rem;
    }
    @media (max-width: theme("screens.md")) {
      font-size: 1rem;
    }
  }
}
</style>
